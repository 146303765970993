<template lang="">
  <div class="container">
    <Banner :title="news"></Banner>
    <div class="new_container wei-container">
      <div class="back pointer" @click="goBack">{{ news.back }}</div>
      <div class="info_container">
        <div class="title ">{{ newsInfo.title }}</div>
        <div class="news_tips">
          <span>{{ news.publish }}{{
              newsInfo.createdTime && newsInfo.createdTime.split(" ")[0]
            }}</span>
          <span>{{ news.readnum }}{{newsInfo.readNum}}次</span>
        
        </div>
        <div class="content" v-html="newsInfo.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { queryArticleInfo } from "@/api/index";
import Banner from "@/components/Banner";
export default {
  components: { Banner },
  data() {
    return {
      news: {},
      newsId: 0,
      newsInfo: {},
    };
  },
  created() {
    this.news = this.$t("news");
    this.newsId = this.$route.query.id;
    this.getQueryArticleInfo();
  },
  mounted() {},
  activated() {
    console.log(1111)
  },
  methods: {
    getQueryArticleInfo() {
      queryArticleInfo(this.newsId).then((res) => {
        this.newsInfo = res.data;
      });
    },
    goBack(){
      this.$router.go(-1)
            scrollTo(0,800)
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 0 200px 0;
  .new_container {
    text-align: left;
    .back {
      margin-top: 100px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      background: #636d7c;
      color: #ffffff;
      text-align: center;
    }
    .info_container {
      margin-top: 40px;

      .title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
      }
      .news_tips {
        text-align: center;
        font-size: 14px;
        color: #aaaaaa;
        margin: 60px 0 80px;
        span {
          margin: 0 36px;
        }
      }
      .content {
        line-height: 1.5;
      }
    }
    .footer {
      height: 20px;
      margin-top: 120px;
      .next {
        float: right;
      }
    }
  }
}
</style>
